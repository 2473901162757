<template>
  <div v-if="!showInMap" class="card bg-light swiper-slide border-card">
    <div class="card-header font-weight-bolder main-header-container py-1">
      <div>
        <div class="name-tag font-14">
          <p class="clamp-2 mb-0 text-uppercase">{{ item.user.first_name }} {{ item.user.last_name }}</p>
          <p class="clamp-2 mb-0">
            {{ item.store_order_invoice_id }}
          </p>
        </div>
      </div>
      <div class="payment-mode-images d-flex">
        <div class="d-flex align-items-center flex-column justify-content-between text-center">
          <template v-if="!isEmpty(item.invoice)">
            <img v-if="item.invoice.payment_mode.toLowerCase() === 'cash'" :src="$helpers.getIcons('cash')" title="Cash Payment" />
            <img v-if="item.invoice.payment_mode.toLowerCase() === 'card'" :src="$helpers.getIcons('card')" title="Card Payment" />
            <img v-if="item.invoice.payment_mode.toLowerCase() === 'wallet'" :src="$helpers.getIcons('wallet')" title="Wallet Payment" />
            <img v-if="item.invoice.payment_mode.toLowerCase() === 'on_delivery'" :src="$helpers.getIcons('card-on-delivery')" title="Card on delivery Payment" />
            <p class="mb-0 payment-mode-images text-uppercase">
              {{ item.paid == 1 ? 'Paid' : 'COD' }}
            </p>
          </template>
        </div>
      </div>
      <div class="rate-block font-18">
        <span class="price-tag"> {{ userCurrency }}
          <template v-if="item.paid && item.invoice.payment_mode.toLowerCase() === 'card'">0</template>
          <template v-else>{{ invoiceTotal.total_amount }}</template>
        </span>
        <span class="light-text"> {{ item.estimated_delivery_time ? item.estimated_delivery_time : item.delivery_time }} </span>
      </div>
    </div>
    <div class="card-body bg-white">
      <div class="d-flex justify-content-between align-items-start pt-1">
        <div>
          <p class="cp m-0 p-0 text-muted" @click="defaultHidePickupAddress ? (hidePickAddress = !hidePickAddress) : null">{{ $t('PICK UP') }}
            <feather-icon v-if="defaultHidePickupAddress" :icon="!hidePickAddress ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="15" />
          </p>
        </div>
        <div v-if="!hidePickAddress">
          <img width="32" class="cursor-pointer mr-10px" :src="`${$apiUrl}/images/icons/call.svg`" @click="storeActions('call')" title="Call" />
          <img width="32" class="cursor-pointer mr-10px" :src="`${$apiUrl}/images/icons/chat.svg`" @click="openChat({to_userable_type: 'App\\Models\\Order\\Store', to_userable_id: item.store_id})" title="Chat" c />
          <a :href="`https://maps.google.com/?q=${item.pickup.latitude},${item.pickup.longitude}`" class="text-underline text-dark" target="_blank">
            <img :src="`${$apiUrl}/images/icons/location.svg`" width="32" title="View Map" />
          </a>
        </div>
      </div>
      <div v-if="!hidePickAddress" class="d-flex justify-content-between">
        <div class="d-flex align-items-start flex-column">
          <div class="font-14">
            <p class="m-0 p-0 text-capitalize">
              <strong>{{ item.pickup ? item.pickup.store_name : '' }}</strong>
            </p>
          </div>
          <div class="font-14">
            <p class="m-0 p-0">
              {{ item.pickup ? item.pickup.store_location : '' }}
            </p>
          </div>
        </div>
      </div>
      <hr v-if="showDeliveryAddress" />
      <div v-if="showDeliveryAddress" class="d-flex justify-content-between align-items-start pt-1">
        <div>
          <p class="m-0 p-0 text-muted">{{ $t('DROP OFF') }}</p>
        </div>
        <div>
          <img v-if="item.contactless_delivery" :src="$helpers.getIcons('contact-less')" height="24" title="Contact less delivery" />
        </div>
        <div>
          <img width="32" class="cursor-pointer mr-10px" :src="`${$apiUrl}/images/icons/call.svg`" @click="userActions('call')" title="Call" />
          <img width="32" class="cursor-pointer mr-10px" :src="`${$apiUrl}/images/icons/chat.svg`" @click="openChat({to_userable_type: 'App\\Models\\Common\\User', to_userable_id: item.user_id})" title="Chat" />
          <a
            v-if="item.delivery && showDeliveryAddress"
            :href="`https://maps.google.com/?q=${item.delivery.latitude},${item.delivery.longitude}`"
            class="text-underline text-dark"
            target="_blank"
          >
            <img :src="`${$apiUrl}/images/icons/location.svg`" width="32" title="View Map" />
          </a>
        </div>
      </div>
      <div v-if="item.delivery && showDeliveryAddress" class="d-flex justify-content-between">
        <div class="d-flex align-items-start flex-column">
          <div class="font-14">
            <p class="m-0 p-0 text-capitalize">
              <strong>{{ item.user.first_name }} {{ item.user.last_name }}</strong>
            </p>
          </div>
          <div class="font-14">
            <AgentDeliveryAddress :deliveryAddress="item.delivery" :key="`AgentDeliveryAddress_${item.id}`" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bottom-button-block bg-white d-flex align-items-center" :class="{ 'justify-content-between': showTimer, 'justify-content-end': !showTimer }">
      <div class="row">
        <div v-if="showTimer" class="col-12 text-right mb-2">{{ $t('Time remaining') }}: {{ countDown }} {{ $t('secs') }}</div>
        <div class="col-12 d-flex justify-content-end align-items-center">
          <img :src="`${$apiUrl}/images/icons/info.svg`" class="mr-3" width="32" @click="that.openViewOrder(item)" />
          <button v-if="showIgnore" type="button" class="btn btn-danger mr-2" @click="that.ignoreOrder(item)">
            {{ $t('Ignore') }}
          </button>
          <button v-if="nextStep !== 'Delivered or Reject'" type="button" class="btn btn-primary" @click="that.updateRequestApproval(item, nextStepStatus)">
            {{ $t(nextStep) }}
          </button>
          <button v-if="nextStep === 'Delivered or Reject'" type="button" class="btn btn-primary" @click="that.updateRequestApproval(item, 'COMPLETED')">
            {{ $t('Delivered') }}
          </button>
          <button v-if="nextStep === 'Delivered or Reject'" type="button" class="btn btn-danger ml-1" @click="that.updateRequestApproval(item, 'DELIVERY_REJECTED')">
            {{ $t('Rejected') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="card bg-light swiper-slide map-card-icon-view border-card">
    <div class="card-header main-header-container py-1 d-flex justify-content-between">
      <div class="name-tag">
        <p class="mb-0">
          {{ item.store_order_invoice_id }}
        </p>
      </div>
      <div class="rate-block d-flex">
        <span class="price-tag mr-1 font-17"> {{ userCurrency }}{{ invoiceTotal.total_amount }} </span>
        <feather-icon icon="XIcon" size="20" @click="hideFromMap" />
      </div>
    </div>
    <div class="card-body bg-white">
      <div v-if="!isEmpty(item.invoice)" class="mt-1">
        <p class="mb-0 payment-mode-images font-15">
          {{ $t('Payment mode: ') }} {{ item.invoice.payment_mode }} <span v-if="item.contactless_delivery">({{ $t('Contactless Delivery') }})</span>
          <span class="d-flex">
            <img v-if="item.invoice.payment_mode === 'CASH'" class="ml-1" :src="$helpers.getIcons('cash')" width="32" />
            <img v-if="item.invoice.payment_mode === 'CARD'" class="ml-1" :src="$helpers.getIcons('card')" width="32" />
            <img v-if="item.invoice.payment_mode === 'on_delivery'" class="ml-1" :src="$helpers.getIcons('card-on-delivery')" width="32" />
            <img v-if="item.invoice.payment_mode === 'contact_less'" class="ml-1" :src="$helpers.getIcons('contact-less')" width="32" />
          </span>
        </p>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="wrapper-agent-pickup-location">
          <div class="light-text pt-1">
            <p class="mb-0">
              <b>{{ $t('PICK UP') }}</b> ({{ item.pickup ? item.pickup.store_name : '' }})
            </p>
          </div>
          <div class="pickup-area-name">
            <p class="mb-0 text-underline" @click="openLocation(item.pickup)">
              {{ item.pickup ? item.pickup.store_location : '' }}
            </p>
          </div>
        </div>
        <div class="wrapper-agent-pickup-location-actions d-flex">
          <img width="32" class="mx-1 cursor-pointer" :src="`${$apiUrl}/images/icons/call.svg`" @click="storeActions('call')" />
          <img width="32" class="mx-1 cursor-pointer" :src="`${$apiUrl}/images/icons/chat.svg`" @click="openChat({to_userable_type: 'App\\Models\\Order\\Store', to_userable_id: item.store_id})" />
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div v-if="item.delivery && showDeliveryAddress" class="wrapper-agent-pickup-location">
          <div class="light-text pt-1">
            <b>{{ $t('DROP OFF') }}</b>
          </div>
          <div class="dropoff-area-name">
            <a :href="`https://www.google.com/maps/search/?api=1&query=${item.delivery.latitude},${item.delivery.longitude}`" class="mb-0 text-underline text-dark" target="_blank">
              {{ item.delivery.map_address }}
            </a>
            <p class="m-0">{{ $t('Flat No') }}: {{ item.delivery.flat_no }}</p>
            <p class="m-0">{{ $t('Building/Villa Name') }}: {{ item.delivery.building }}</p>
            <p class="m-0">{{ $t('Street') }}: {{ item.delivery.street }}</p>
          </div>
        </div>
        <div class="wrapper-agent-pickup-location-actions d-flex">
          <img width="32" class="mx-1 cursor-pointer" :src="`${$apiUrl}/images/icons/call.svg`" @click="userActions('call')" />
          <img width="32" class="mx-1 cursor-pointer" :src="`${$apiUrl}/images/icons/chat.svg`" @click="openChat({to_userable_type: 'App\\Models\\Common\\User', to_userable_id: item.user_id})" />
        </div>
      </div>
    </div>
    <div class="card-footer bottom-button-block bg-white d-flex align-items-center justify-content-end">
      <div class="wrapper-card-actions-button">
        <button v-if="nextStep !== 'Delivered or Reject'" type="button" class="btn btn-primary" @click="that.updateRequestApproval(item, nextStepStatus)">
          {{ $t(nextStep) }}
        </button>
        <button v-if="nextStep === 'Delivered or Reject'" type="button" class="btn btn-primary" @click="that.updateRequestApproval(item, 'COMPLETED')">
          {{ $t('Delivered') }}
        </button>
        <button v-if="nextStep === 'Delivered or Reject'" type="button" class="btn btn-danger ml-1" @click="that.updateRequestApproval(item, 'REJECTED')">
          {{ $t('Reject') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AgentDeliveryAddress from '@/views/provider/shared/AgentDeliveryAddress'
import eventBus from '@/libs/event-bus'
import { isEmpty } from '@/utilities'
export default {
  components: {
    AgentDeliveryAddress,
  },
  props: {
    that: {
      type: Object,
      default: () => {},
    },
    showIgnore: {
      type: Boolean,
      default: true,
    },
    showTimer: {
      type: Boolean,
      default: false,
    },
    showInMap: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: () => 0,
    },
    item: {
      type: Object,
      default: () => {},
    },
    orderDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countDown: 60,
      interval: null,
      hidePickAddress: false
    }
  },
  computed: {
    nextStep() {
      if (!this.item) return 'Accept'
      if (this.item.status === 'ASSIGNED') return 'On the way'
      if (this.item.status === 'PROCESSING') return 'Accept'
      if (this.item.status === 'STARTED') return 'At pickup location'
      if (this.item.status === 'REACHED') return 'Picked up'
      if (this.item.status === 'PICKEDUP') return 'Out for delivery'
      if (this.item.status === 'OUT_FOR_DELIVERY') return 'Arrived'
      // add two buttons for delivered or rejected
      if (this.item.status === 'ARRIVED') return 'Delivered or Reject'
      if (this.item.status === 'DELIVERED') return 'Order Completed'
      return 'Accept'
    },
    nextStepStatus() {
      if (!this.item) return ''
      if (this.item.status === 'PROCESSING') return ''
      if (this.item.status === 'ASSIGNED') return 'STARTED'
      if (this.item.status === 'STARTED') return 'REACHED'
      if (this.item.status === 'REACHED') return 'PICKEDUP'
      if (this.item.status === 'PICKEDUP') return 'OUT_FOR_DELIVERY'
      if (this.item.status === 'OUT_FOR_DELIVERY') return 'ARRIVED'
      if (this.item.status === 'ARRIVED') return 'DELIVERED'
      if (this.item.status === 'DELIVERED') return 'COMPLETED'
      return ''
    },
    defaultHidePickupAddress: {
      get(){
        if (this.item.status == 'PICKEDUP') return true;
        if (this.item.status == "OUT_FOR_DELIVERY") return true;
        if (this.item.status == 'ARRIVED') return true;
        if (this.item.status == 'DELIVERED') return true;
        return false;
      }
    },
    showDeliveryAddress: {
      get() {
        if (this.item.status == 'PICKEDUP') return true;
        if (this.item.status == "OUT_FOR_DELIVERY") return true;
        if (this.item.status == 'ARRIVED') return true;
        if (this.item.status == 'DELIVERED') return true;
        return this.item?.show_delivery_address ?? false;
      },
    },
    getBase() {
      // eslint-disable-next-line no-nested-ternary
      return !isEmpty(this.item.invoice) ? this.item.invoice : this.item.order_invoice ? this.item.order_invoice : {}
    },
    userCurrency() {
      const cart = this.getBase
      if (cart.items && cart.items[0] && cart.items[0].store) {
        return cart.items[0].store.currency_symbol
      }
      return ''
    },
    invoiceTotal() {
      return this.getBase
    },
  },
  mounted() {
    this.countDownTimer()
    this.hidePickAddress = this.defaultHidePickupAddress
  },
  destroyed() {
    if (this.interval) {
      clearTimeout(this.interval)
    }
  },
  methods: {
    isEmpty,
    storeActions(action) {
      if (action === 'message') {
        this.that.openStoreChat(this.item)
      } else if (action === 'call') {
        if (this.item && this.item.store && this.item.store.mobile) {
          window.open(`tel:+${this.item.store.country_code ? this.item.store.country_code : ''}${this.item.store.mobile}`)
        }
        if (this.item && this.item.store && this.item.store.contact_number) {
          window.open(`tel:+${this.item.store.country_code ? this.item.store.country_code : ''}${this.item.store.contact_number}`)
        }
      }
    },
    openChat(toData){
      this.that.setChatInfo(this.item, toData)
    },
    userActions(action) {
      if (action === 'message') {
        
        // if (this.item && this.item.user && this.item.user.mobile) {
        //   window.open(`https://wa.me/${this.item.user.country_code ? this.item.user.country_code : ''}${this.item.user.mobile}/?text=Hello`)
        // }
      } else if (action === 'call') {
        if (this.item && this.item.user && this.item.user.mobile) {
          window.open(`tel:+${this.item.user.country_code ? this.item.user.country_code : ''}${this.item.user.mobile}`)
        }
      }
    },
    showOnMap() {
      eventBus.$emit('show-details-on-map', this.item)
      this.$emit('hide-orders-modal')
    },
    hideFromMap() {
      eventBus.$emit('show-details-on-map', null)
    },
    openLocation(payload) {
      if (payload) {
        window.open(`https://maps.google.com/?q=${payload.latitude},${payload.longitude}`)
      }
    },
    countDownTimer() {
      if (this.showTimer) {
        if (this.countDown > 0) {
          this.interval = setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
        if (this.countDown === 0) {
          this.that.ignoreOrder(this.item, true)
        }
      }
    },
    openCall() {
      if (this.orderDetail && this.orderDetail.user && this.orderDetail.user.mobile) {
        window.open(`tel:+${this.orderDetail.user.country_code ? this.orderDetail.user.country_code : ''}${this.orderDetail.user.mobile}`)
      }
    },
  },
}
</script>

<style scoped>
.payment-mode-images img {
  width: 30px;
}
.mr-10px {
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .map-card-icon-view {
    zoom: 0.6;
  }
}
.border-card{ 
  border: 1px solid #C9C9C9
}
</style>
